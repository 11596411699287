export const loginActions = {

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    // DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    // DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    // DELETE_FAILURE: 'USERS_DELETE_FAILURE'
};


export function loginSuccessAction(data) {
    console.log(data, 'login');
    return {
        type: loginActions.LOGIN_SUCCESS,
        token: data.token,
        socketChannel: data.data.socketChannel,
        permissions: data.data.permissions
    };
}

export function logoutAction() {
    return {
        type: loginActions.LOGOUT,
    };
}


export function userReducer(state = {token: null, name: null}, action) {
    switch (action.type) {
        case loginActions.LOGIN_SUCCESS:
            state = {...state, token: action.token, socketChannel: action.socketChannel, permissions: action.permissions};
            console.log(state, 'state');
            sessionStorage.setItem('user', JSON.stringify(state));
            return state;

        case loginActions.LOGOUT:
            state = {...state, token: null};
            sessionStorage.setItem('user', JSON.stringify(state));
            return state;

        case 'GET_DATA_FROM_LOCAL_STORAGE':
            let user = JSON.parse(sessionStorage.getItem('user') || '[]');
            if (user) {
                return {
                    ...state,
                    token: user.token,
                    socketChannel: user.socketChannel,
                    permissions: user.permissions
                };
            }
            break;

        default:
            return state;
    }
}
