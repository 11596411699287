import React, {Component} from 'react';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga'

//Pages
import AppContainer from "./containers/App/AppContainer";

import rootReducer from './state/reducers/rootReducer';

// import {ReactActionSocketMiddleware} from 'react-redux-socket/client'

import '../node_modules/antd/dist/antd.css';
import './App.css';

//Sagas
import metadataSaga from './state/sagas/metadataSaga';

require('dotenv').config();


const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(metadataSaga);

class App extends Component {

    render() {

        return (
            <Provider store={store}>
                <AppContainer/>
            </Provider>
        );
    }
}

export default App;
