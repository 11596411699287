// import {fromJS} from 'immutable';

// The initial state of the App
const initialState = {
    loaded: false,
    queues: [],
    agents: [],
};

function metadataReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOAD_METADATA_SUCCESS':
            let newstate = {
                ...state,
                ...action.metadata,
                loaded: true
            };
            return newstate;

        default:
            return state;
    }
}

export default metadataReducer;
