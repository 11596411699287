import React from 'react';
import MainForm from "../../components/MainForm/MainForm";
import ChatForm from "../ChatForm";
import Loading from "../../components/Loading";
import {Col, Row} from 'antd';
import {getCallById} from "../../api/apiFactory";
import connect from "react-redux/es/connect/connect";

/* eslint-disable react/prefer-stateless-function */
export class CallPage extends React.PureComponent {

    state = {
        loading: true,
        callId: 'new',
        call: {id: 'new'},
    };


    componentDidMount() {
        let callId = this.props.match.params.id;
        this.setState({callId: callId});
        this.loadCallData(callId);
    };


    componentWillReceiveProps(nextProps) {
        const callId = nextProps.match.params.id;
        this.loadCallData(callId);
    }


    loadCallData = async (callId) => {
        try {
            if (callId && typeof callId !== 'undefined') {
                let data = await getCallById(callId);
                // console.log(data);
                this.setState({call: {...data}, loading: false});
            } else {
                this.setState({call: {id: 'new'}});
            }
        } catch (e) {
            console.log(['Rejected', e])
        }
    };


    render() {
        if (this.state.loading || !this.props.metadata_loaded) {
            return (<Loading/>);
        }

        return (
            <Row gutter={16}>
                <Col span={16}>
                    <MainForm call={this.state.call} callId={this.state.callId} metadata={this.props.metadata}/>
                </Col>
                <Col span={8}>
                    {
                        this.state.call && this.state.call.chatId &&
                        <ChatForm activeChat={this.state.call.chatId}/>
                    }
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        metadata: state.metadata,
        metadata_loaded: state.metadata.loaded
    };
};

export default connect(mapStateToProps)(CallPage);
