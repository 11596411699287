import React, {PureComponent} from 'react';
// import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import {store} from "../../App";
import Config from "../../config";
import {SocketConnectAction, SocketDisconnectAction} from "../../state/socketState";

class Sockets extends PureComponent {

    autoReconnectInterval = 2000;
    reconnectTry = 0;

    state = {
        status: 'Connected',
        channel: null,
    };

    socketOpen = (socketChannel) => {
        const socket = new WebSocket(Config.socketUrl);

        socket.onopen = () => {
            this.reconnectTry = 0;
            console.log('CONNECTED');
            socket.send(JSON.stringify({
                type: 'JOIN',
                channel: socketChannel
            }))

            // socket.send(JSON.stringify({
            //     channel: '101',
            //     type: 'CALL_ANSWERED',
            //     data: {tt: "asdasdasd"}
            // }))

            store.dispatch(SocketConnectAction());

        };

        socket.onclose =
            () => {
                store.dispatch(SocketDisconnectAction());

                console.log('DISCONNECTED');
                this.setState({status: 'Disconnected'});
                this.socketReconnect(socketChannel)
            };

        socket.onerror =
            () => {
                console.log('ERROR');
            };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data)
            console.log(data);
            store.dispatch(data);


            // switch (data.type) {
            //     case 'ADD_MESSAGE':
            //         // dispatch(messageReceived(data.message, data.author))
            //         break
            //     default:
            //         break
            // }
        }
    };

    socketReconnect = (socketChannel) => {
        let reconnectInterval = this.reconnectTry > 10  ? this.autoReconnectInterval : 0;
        this.reconnectTry++;
        console.log(`WebSocketClient: retry in ${this.autoReconnectInterval}ms`);
        // this.instance.removeAllListeners();
        setTimeout( () => {
            console.log("WebSocketClient: reconnecting...");
            this.socketOpen(socketChannel);
        }, reconnectInterval);
    };


    componentWillReceiveProps(nextProps) {

        if (!nextProps.user.socketChannel){
            return;
        }

        this.socketOpen(nextProps.user.socketChannel);
    }

    static propTypes = {};

    render() {
        return (
            <div>
            </div>
        );
    }
}



const mapStateToProps = function (state) {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Sockets);


