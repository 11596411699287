/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';

/* eslint-disable react/prefer-stateless-function */
export class HomePage extends React.PureComponent {
  render() {
    return (
     <div>
       Страница отображаемая в режиме "ожидания"
     </div>
    );
  }
}
export default HomePage;
