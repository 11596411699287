/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import {getMetadata as getMetadataRequest} from '../../api/apiFactory';

/**
 * Github repos request/response handler
 */
export function* getMetadataData() {
  // Select username from store


  try {
    // Call our request helper (see 'utils/request')
    const metadata = yield call(getMetadataRequest);
    yield put({type:'LOAD_METADATA_SUCCESS', metadata: metadata});
  } catch (err) {
    // yield put(repoLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getMetadata() {
  yield takeLatest('LOAD_METADATA', getMetadataData);
}
