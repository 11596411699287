import React from 'react';
import {Button, notification} from 'antd';
import {acceptChat} from "../api/apiFactory";

import soundfile from "../push.wav";

export const chatActions = {
    NEW_CHAT: 'NEW_CHAT',
    NEW_MESSAGE: 'NEW_MESSAGE',
    CALL_ALREADY_ASSIGNED: 'CALL_ALREADY_ASSIGNED',
    agentName: 'agentName',
};


export function newChatAction(id) {
    console.log(id);
    return {
        type: chatActions.NEW_CHAT,
        id: id,
    };
}

export function newMessages(chatId, lastMessage) {
    return {
        type: chatActions.NEW_MESSAGE,
        chatId,
        lastMessage
    };
}


export function chatReducer(state = {chatId: null, lastUpdate: null}, action) {
    switch (action.type) {
        case chatActions.NEW_CHAT:
            openNotification(action.id);
            return state;

        case chatActions.NEW_MESSAGE:
            state = {...state, chatId: action.chatId, lastMessage: action.lastMessage};
            return state;

        case chatActions.CALL_ALREADY_ASSIGNED:
            closeNotification(action.id, action.agentName);
            return state;

        default:
            return state;
    }
}


const openNotification = (chatId) => {
    let audio = new Audio(soundfile);
    audio.play();
    const key = chatId;
    const btn = (
        <div>
            <Button type="primary" size="small" onClick={() => {
                acceptChat(chatId);
                notification.close(key);
            }}>
                Принять
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button type="primary" size="small" onClick={() => {
                console.log('Отклонить');
                notification.close(key);
            }}>
                Отклонить
            </Button>
        </div>
    );
    notification.open({
        message: 'Запрос на создание нового чата',
        description: '',
        btn,
        key,
        onClose: () => {
        },
        duration: 0,
    });
};


const closeNotification = (chatId,name) => {
    const key = chatId;
    const agentName = name;
    const btn = (
        <div>
            <Button type="primary" size="small" onClick={() => {
                console.log('Ок');
                notification.close(key);
            }}>
                Ок
            </Button>
        </div>
    );
    notification.open({
        message: 'Предупреждение',
        description: 'Данное обращение уже принял оператор: ' + agentName,
        btn,
        key,
        onClose: () => {
        },
        duration: 3,

    });
};
