import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class Loading extends PureComponent {

    static propTypes = {
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: true,
    };

    render() {

        const blureCss = this.props.isLoading ? {filter: "grayscale(100%) blur(5px)", minHeight: "100px"} : {};

        return (
            <div style={{position: "relative"}}>
                    {this.props.isLoading &&
                    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        <h2>Loading</h2></div>}
                    <div style={blureCss}>{this.props.children}</div>
            </div>
        );
    }
}

export default Loading;
