import React  from 'react';
import {Table} from 'antd';
import {getCalls} from "../../api/apiFactory";
import Filter from "./filter";

/* eslint-disable react/prefer-stateless-function */
export class CallListPage extends React.PureComponent {

    state = {
        loading: true,
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0,
        },
        order: {},
        filter: {
            id: null,
            status: null,
            clientName: null,
            clientPhone: null,
            timeFrom: null,
            timeTo: null
        },
        sorter: {
            field: 'id', order: 'desc'
        },
    };

    componentDidMount() {
        this.setState({loading: true});

        this.handleTableChange(this.state.pagination);
        if (JSON.parse(localStorage.getItem("filter")) != null)
        {
            const filter = JSON.parse(window.localStorage.getItem('filter'));
            const filters = {...this.state.filter};
            if (filter['id'] != null) {
                filters.id = filter['id'];
            }
            if (filter['status'] != null) {
                filters.status = filter['status'];
            }
            if (filter['clientName'] != null) {
                filters.clientName = filter['clientName'];
            }
            if (filter['clientPhone'] != null) {
                filters.clientPhone = filter['clientPhone'];
            }
            if (filter['timeFrom'] != null) {
                filters.timeFrom = filter['timeFrom'];
            }
            if (filter['timeTo'] != null) {
                filters.timeTo = filter['timeTo'];
            }

            this.setState({
                filter: filters,
            }, this.reloadTable);
        }

        if (JSON.parse(localStorage.getItem("pagination")) != null)
        {
            this.setState({pagination: JSON.parse(localStorage.getItem("pagination"))});

            console.log('Pagination: true');
        }

        if (JSON.parse(localStorage.getItem("sorter")) != null)
        {
            this.setState({sorter: JSON.parse(localStorage.getItem("sorter"))});
            console.log('Sorter: true');
        }


        this.fetch(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter,
            this.state.filter,
        );
    };


    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        }, this.reloadTable);
        console.log(this.state);
       // const pager = {...this.state.pagination};

    };

    fetch = async (page, limit, sorter, filters) => {
        this.setState({loading: true});



        let sortParam = {};
        if (sorter.field) {
            let sortName = 'sort[' + sorter.field + ']';
            sortParam = {[sortName]: sorter.order};
        }

        let filterParam = {};
        if (filters) {
            Object.keys(filters).forEach(
                key => {
                    let filterName = 'filter[' + key + ']';
                    filterParam = {...filterParam, [filterName]: filters[key]};
                }
            );
        }

        try {
            let data = await getCalls(
                {
                    limit: limit,
                    page: page,
                    ...sortParam,
                    // sortField: sorter.field,
                    // sortOrder: sorter.order,
                    ...filterParam,
                });

            this.setState({
                calls: data.data,
                pagination: {
                    ...this.state.pagination,
                    pageSize: limit,
                    current: page,
                    total: data.meta.total
                },
                loading: false
            });


        } catch (e) {
            console.log(['Rejected', e])
        }
    };

    reloadTable = () => {
        this.fetch(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter,
            this.state.filter
        );
        // localStorage.removeItem('pagination');
    };



    applyFilter = () => {
        this.setState({
                pagination: {
                    ...this.state.pagination,
                    current: 1,
                }
            },
            this.reloadTable
        );
    };


    editClick = (e) => {
        localStorage.setItem('sorter', JSON.stringify(this.state.sorter));
        localStorage.setItem('filter', JSON.stringify(this.state.filter));
        localStorage.setItem('pagination', JSON.stringify(this.state.pagination));

        const record = e.target.getAttribute('record');
        this.props.history.push('call/' + record);
    };

    render() {
        // if (this.state.loading) {
        //     return (<Loading/>);
        // }

        let dataSource = this.state.calls;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                sorter: true
            },
            {
                title: 'Время обращения',
                dataIndex: 'createdAt',
                key: '1',
            },
            {
                title: 'Тип обращения',
                dataIndex: 'typeTitle',
                key: '4',
            },
            {
                title: ' Статус',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Тема',
                dataIndex: 'topicTitle',
                key: '2',
            },
            {
                title: 'Подтема',
                dataIndex: 'subTopicTitle',
                key: '3',
            },
            {
                title: 'ФИО',
                dataIndex: 'clientName',
                key: '5',
            },
            {
                title: 'Телефон',
                dataIndex: 'clientPhone',
                key: '6',
            },
            {
                title: 'Оператор',
                dataIndex: 'agentName',
                key: '7',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {record.file &&
                        <a href={record.file} style={{paddingRight: "20px"}} target={"_top"}>Слушать</a>}
                        <a href={`/call/${record.id}/edit`} onClick={this.editClick}
                           record={record.id}>Просмотреть</a>
                    </span>
                ),
            }
        ];

        return (
            <div>
                <Filter
                    filter={this.state.filter}
                    changeFilter={(filter) => {
                        this.setState({filter})
                    }}
                    applyFilter={this.applyFilter}
                />
                <Table
                    rowKey="id"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                />

            </div>
        );
    }
}

export default CallListPage;
