import React from 'react';
import MainForm from "../../components/MainForm/MainForm";
import ChatForm from "../ChatForm";
import Loading from "../../components/Loading";
import {Col, Row} from 'antd';
import {getCallById, getMetadata} from "../../api/apiFactory";

/* eslint-disable react/prefer-stateless-function */
export class CallPage extends React.PureComponent {

    state = {
        loading: true,
        callId: 'new',
        call: {id: 'new'},
    };


    componentDidMount() {
        let callId = this.props.match.params.id;
        this.setState({callId: callId});
        this.loadCallData(callId);
    };


    componentWillReceiveProps(nextProps) {
        const callId = nextProps.match.params.id;
        // if (this.props.match.params.id != callId) {
        this.loadCallData(callId);
        // }
    }


    loadCallData = (callId) => {
        if (callId && typeof callId !== 'undefined') {
            getMetadata()
                .then((data) => {
                    // console.log(data);
                    this.setState({metadata: data});
                })
                .then(
                    () => {
                        getCallById(callId)
                            .then((data) => {
                                console.log(data);
                                this.setState({call: {...data}});
                            })
                            .catch((reject) => {
                                console.log(['Rejected', reject])
                            });
                    }
                )
                .then(() => {
                    this.setState({loading: false});
                })
                .catch((reject) => {
                    console.log(['Rejected', reject])
                });
        } else {
            this.setState({call: {id: 'new'}});
        }
    };


    render() {
        if (this.state.loading) {
            return (<Loading/>);
        }

        return (
            <Row gutter={16}>
                {this.state.call}
                <Col span={16}>
                    <MainForm callId={this.state.callId}/>
                </Col>
                <Col span={8}>
                    {
                        this.state.call && this.state.call.chatId &&
                        <ChatForm activeChat={this.state.call.chatId}/>
                    }
                </Col>
            </Row>
        );
    }
}

export default CallPage;
