import React from 'react';
import PropTypes from 'prop-types';
import {getChatData, newMessage} from "../../api/apiFactory";
import Messages from "./messages";
import InputLine from "./inputLine";
import connect from "react-redux/es/connect/connect";

class ActiveChatForm extends React.PureComponent {

    static propTypes = {
        activeChat: PropTypes.number,
    };

    state = {
        chat: null,
        messages: null,
    };

    componentDidMount() {
        this.reloadMessages();
    }


    componentWillReceiveProps(nextProps) {
        if(this.props.activeChat == nextProps.chat.chatId
            && this.props.chat.lastMessage != nextProps.chat.lastMessage
        ){
            this.reloadMessages();
        }
    }


    reloadMessages = () => {
        getChatData(this.props.activeChat)
            .then(data => {
                console.log(data);
                this.setState({messages: data.messages, chat: data.chat});
            })
            .catch((reject) => {
                console.log(['Rejected', reject]);
            });
    };

    sendMessage = (message) => {
        newMessage(this.props.activeChat, message)
            .then(data => {
                this.reloadMessages();
            })
            .catch((reject) => {
                console.log(['Rejected', reject]);
            });
    };

    render() {

        return (
            <div style={{background: "#f1f3f0", borderRadius: "10px", padding: "5px"}}>
                <h5 style={{padding: "5px"}}>Окно чата:</h5>
                <div style={{padding: "15px", overflow:"scroll", overflowX: "hidden", height: "500px"}}>
                <Messages messages={this.state.messages}/>
                </div>
                <div></div>
                <div style={{width: "100%", height: "100%"}}>
                <InputLine sendClick={this.sendMessage}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        chat: state.chat,
    };
};

export default connect(mapStateToProps)(ActiveChatForm);
