import request from './request';

import Config from '../config'

const ApiUrl = ()=> {
    return Config.apiUrl;
};

// SYSTEM

export function loginCheck(user, pass) {
    return request(ApiUrl() + '/login_check',
        {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "username": user,
                "password": pass
            }),
            withCredentials: true,
            credentials: 'same-origin',
        })
}

export function getMetadata() {
    return request(ApiUrl() + '/metadata', addAuthHeaders({}));
}


export function getCalls(params) {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    return request(ApiUrl() + '/operator/call?' + query, addAuthHeaders({}));
}

export function getCallById(id) {
    return request(ApiUrl() + '/operator/call/' + id, addAuthHeaders({}));
}

export function saveCall(id, data) {
    return request(ApiUrl() + '/operator/call/' + id,
        addAuthHeaders(
            {
                method: 'post',
                body: JSON.stringify(data),
                withCredentials: true,
                credentials: 'same-origin',
            }
        )
    )
}

export function sendCallBnx(id, data) {
    return request(ApiUrl() + `/operator/call/${id}/send` ,
        addAuthHeaders(
            {
                method: 'post',
                body: JSON.stringify(data),
                withCredentials: true,
                credentials: 'same-origin',
            }
        )
    )
}

//  CHAT

export function acceptChat(chatId) {
    return request(ApiUrl() + '/operator/chat/' + chatId + '/accept', addAuthHeaders({}));
}

export function getChatData(chatId) {
    return request(ApiUrl() + '/operator/chat/' + chatId, addAuthHeaders({}));
}

export function newMessage(chat, text) {
    return request(ApiUrl() + '/operator/chat/' + chat + '/message',
        addAuthHeaders(
            {
                method: 'post',
                body: JSON.stringify({text}),
                withCredentials: true,
                credentials: 'same-origin',
            }
        )
    )
}


function addAuthHeaders(options) {
    let user = JSON.parse(sessionStorage.getItem('user') || '[]');
    options = {
        ...options,
        headers:
            {...options.headers, 'Content-Type': 'application/json'}
    }
    if (user.token) {
        options = {
            ...options,
            headers:
                {...options.headers, 'Authorization': 'Bearer ' + user.token}
        };
        return options;
    }
    return options;
}


// // DISTRIBUTION
//
//
// export function getDistribution(group = "queueName", form, limit, offset) {
//     return request(ApiUrl() + '/distribution/by/' + group + "?" + formToUrl(form) + paginationToUrl(limit, offset))
// }
//
// export function getDistributionChartCalls(form) {
//     return request(ApiUrl() + '/distribution/chart/calls?' + formToUrl(form));
// }
//
// export function getDistributionChartHour(form) {
//     return request(ApiUrl() + '/distribution/chart/hour?' + formToUrl(form));
// }
//
// export function getDistributionSummary(form) {
//     return request(ApiUrl() + '/distribution/summary?' + formToUrl(form))
// }
//
//
// // ANSWERED
//
// export function getAnsweredSummary(form) {
//     return request(ApiUrl() + '/answered/summary?' + formToUrl(form))
// }
//
// export function getAnsweredServiceLevel(form) {
//     return request(ApiUrl() + '/answered/service_level?' + formToUrl(form))
// }
//
// export function getAnsweredFullReport(form, limit, offset) {
//     if (!limit) {
//         limit = 20;
//     }
//     return request(ApiUrl() + '/answered/full_report?' + formToUrl(form) + paginationToUrl(limit, offset));
// }
//
//
//
// // EXTRA
//
// function paginationToUrl(limit, offset) {
//     let query = '';
//     // console.log(form);
//     if (limit > 0) {
//         query += '&page[limit]=' + limit;
//     }
//     if (offset > 0) {
//         query += '&page[offset]=' + offset;
//     }
//     return query;
// }
//
// function formToUrl(form) {
//     let query = '';
//     // console.log(form);
//     if (form.dateFrom) {
//         query += '&filter[from_date]=' + form.dateFrom;
//     }
//     if (form.dateTo) {
//         query += '&filter[to_date]=' + form.dateTo;
//     }
//     if (form.queuesSelected.length) {
//         query += '&filter[queues]=' + form.queuesSelected.reduce(
//             (accumulator, currentValue) => {
//                 return accumulator + ',' + encodeURI(currentValue);
//             }
//         );
//     }
//     if (form.agentsSelected.length) {
//         query += '&filter[agents]=' + form.agentsSelected.reduce(
//             (accumulator, currentValue) => {
//                 return accumulator + ',' + encodeURI(currentValue);
//             }
//         );
//     }
//     // console.log(query);
//     return query;
// }
