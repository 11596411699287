export const socketActions = {
    CONNECT: 'CONNECTED',
    DISCONNECT: 'DISCONNECTED',
};


export function SocketConnectAction() {
    return {
        type: socketActions.CONNECT,
        socketConnected: true
    };
}

export function SocketDisconnectAction() {
    return {
        type: socketActions.DISCONNECT,
        socketConnected: false
    };
}


export function socketReducer(state = {socketConnected: false}, action) {
    switch (action.type) {
        case socketActions.CONNECT:
            return {...state, socketConnected: true};

        case socketActions.DISCONNECT:
            return {...state, socketConnected: false};

        default:
            return state;
    }
}
