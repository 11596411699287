import React, {Component} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import Header from '../../components/Header';
import {Layout} from 'antd';

//Pages
import HomePage from "../HomePage";
import LoginPage from "../LoginPage";
import CallEditPage from "../CallEditPage";
import CallViewPage from "../CallViewPage";
import CallListPage from "../CallListPage";


import 'bootstrap/dist/css/bootstrap.min.css';

import Sockets from "../../components/Sockets";

const {Content, Footer} = Layout;

class AppContainer extends Component {


    componentDidMount() {
        this.props.dispatch({type: 'GET_DATA_FROM_LOCAL_STORAGE'});
        this.props.dispatch({type: 'LOAD_METADATA'});
    }


    render() {

        console.log(this.props, 'container');

        return (
            <Layout className="layout">
                <BrowserRouter basename={''}>
                    <div>
                        <Sockets/>
                        <Header/>
                        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
                            <Content style={{padding: '0 50px'}}>
                                <Route path="/" exact component={HomePage}/>
                                <Route path="/login" exact component={LoginPage}/>
                                {/*<Route path="/call/new/edit" exact component={CallEditPage}/>*/}
                                <Route path="/call/:id/edit" exact component={CallEditPage}/>
                                {/*<Route path="/call/:id" exact component={CallEditPage}/>*/}
                                <Route path="/call/:id/view" exact component={CallViewPage}/>
                                <Route path="/call" exact component={CallListPage}/>
                            </Content>
                        </div>
                        <Footer style={{textAlign: 'center'}}>
                            Itach-soft ©2019
                        </Footer>
                    </div>
                </BrowserRouter>

            </Layout>
        );
    }
}


const mapStateToProps = function (state) {
    return {
        loaded: state.metadata.loaded,
        metadata: state.metadata
    };
};

export default connect(mapStateToProps)(AppContainer);
