import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

class InputLine extends PureComponent {

    static propTypes = {
        sendClick: PropTypes.func,
    };

    state = {
        text: "",
    };

    send = () => {
        this.setState({text: ""});
        if (this.state.text.replace(/\s/g, '').length)
        this.props.sendClick(this.state.text)
    };

    render() {
        return (
            <div style={{display: "flex", borderRadius: "5px", border: "1px solid #CCC",
                backgroundColor: "white", bottom: "10px"}}>
                <textarea style={{width: "400px", border: "0", height: "auto"}}
                    value={this.state.text}
                    onChange={
                        (e) => {
                            this.setState({text: e.target.value})
                        }
                    }
                    onKeyPress={
                        (e) => {
                            if (e.key === 'Enter') {
                                if (this.state.text.replace(/\s/g, '').length)
                                {
                                this.send();
                                }
                            }
                        }
                    }
                >{this.state.text}</textarea>
                <div style={{width: "100px", float: "right", cursor: "pointer", margin: "auto", padding: "0 0 0 15px",}} onClick={this.send} onSubmit={this.send}> Отправить</div>
            </div>
        );
    }
}

export default InputLine;
