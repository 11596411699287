// import {fromJS} from 'immutable';

// The initial state of the App
const initialState = {
    queuesSelected: [], //sessionStorage.getItem('queuesSelected') ? JSON.parse(sessionStorage.getItem('queuesSelected')) : [],
    agentsSelected: [],
    dateFrom: ''
};

function metadataReducer(state = initialState, action) {
    switch (action.type) {
        case 'FORM_SELECTED_QUEUES':
            sessionStorage.setItem('queueSelected', JSON.stringify(action.selected));
            return {...state, queuesSelected: action.selected};

        case 'FORM_SELECTED_AGENTS':
            sessionStorage.setItem('agentsSelected', JSON.stringify(action.selected));
            return {...state, agentsSelected: action.selected};

        case 'FORM_UPDATE':
            sessionStorage.setItem(action.field, JSON.stringify(action.value));
            return {...state, [action.field]: action.value};

        case 'GET_DATA_FROM_LOCAL_STORAGE':
            let queuesSelected = JSON.parse(sessionStorage.getItem('queueSelected') || '[]');
            let agentsSelected = JSON.parse(sessionStorage.getItem('agentsSelected') || '[]');
            let dateFrom = JSON.parse(sessionStorage.getItem('dateFrom') || '[]');
            let dateTo = JSON.parse(sessionStorage.getItem('dateTo') || '[]');
            return {
                ...state,
                queuesSelected: queuesSelected,
                agentsSelected: agentsSelected,
                dateFrom: dateFrom,
                dateTo: dateTo
            };

        default:
            return state;
    }
}

export default metadataReducer;
