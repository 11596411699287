export const callActions = {
    OPEN_CALL: 'OPEN_CALL',
};


export function openCallAction(id) {
    console.log(id);
    return {
        type: callActions.OPEN_CALL,
        id: id,
    };
}


export function callReducer(state = {token: null, name: null}, action) {
    switch (action.type) {
        case callActions.OPEN_CALL:
            window.location.href = '/call/' + action.id + '/edit';
            return state;

        default:
            return state;
    }
}
