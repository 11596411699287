import React from 'react';
import {Redirect} from 'react-router';

import {loginSuccessAction, logoutAction} from '../../state/userState'
import {store} from '../../App';

import {
    Form, Icon, Input, Button, Checkbox, Row, Col
} from 'antd';
import {loginCheck} from "../../api/apiFactory";

/* eslint-disable react/prefer-stateless-function */
export class LoginPage extends React.PureComponent {


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                store.dispatch(logoutAction());
                loginCheck(values.userName, values.password).then((data) => {
                    console.log(data);
                    store.dispatch(loginSuccessAction(data));
                    this.setState({redirect: true});
                }).catch((reject) => {
                    console.log(['Rejected', reject])
                });
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        if (store && store.getState().user.token){
            return <Redirect to={'/'}></Redirect>
        }

        return (
            <Row type="flex" align="middle">
                <Col md={10}></Col>
                <Col md={10}>

                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('userName', {
                                rules: [{required: true, message: 'Please input your username!'}],
                            })(
                                <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                       placeholder="Username"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: 'Please input your Password!'}],
                            })(
                                <Input prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                                       placeholder="Password"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(
                                <Checkbox>Remember me</Checkbox>
                            )}
                            {/*<a className="login-form-forgot" href="">Forgot password</a>*/}
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col md={10}></Col>
            </Row>
        );
    }
}


const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(LoginPage);

export default WrappedNormalLoginForm;
