import React, {Component} from 'react';
import {Icon} from 'antd';
import connect from "react-redux/es/connect/connect";

class SocketState extends Component {

    render() {
        return (
            <div style={{color: "#FFF"}}>
                {
                    this.props.socketConnected &&
                    <table>
                        <tr>
                            <td>
                                <div style={{marginTop: -3, marginRight: 6}}>
                                    <Icon type="check-circle"
                                          theme="outlined"
                                          style={{fontSize: '16px', color: '#52c41a', paddingBottom: "10px"}}
                                    />
                                </div>
                            </td>
                            <td>
                                Connected
                            </td>
                        </tr>
                    </table>
                }
                {
                    !this.props.socketConnected &&
                    <table>
                        <tr>
                            <td>
                                <div style={{marginTop: -3, marginRight: 6}}>
                                    <Icon type="close-circle"
                                          theme="outlined"
                                          style={{fontSize: '16px', color: '#c4521a', paddingBottom: "10px"}}
                                    />
                                </div>
                            </td>
                            <td>
                                Disconnected
                            </td>
                        </tr>
                    </table>
                }
            </div>
        );
    }
}

SocketState.propTypes = {};

const mapStateToProps = function (state) {
    return {
        socketConnected: state.socket.socketConnected,
    };
};

export default connect(mapStateToProps)(SocketState);
