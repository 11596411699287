import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

const processString = require('react-process-string');

class Messages extends PureComponent {

    static propTypes = {
        messages: PropTypes.array,
    };

    state = {};



    render() {
        if (!this.props.messages) {
            return (<div></div>);
        }

        let messages = [];

        let config = [{
            regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
            fn: (key, result) => <span key={key}>
                                     <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                                 </span>
        }, {
            regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
            fn: (key, result) => <span key={key}>
                                     <a target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                                 </span>
        }];

        this.props.messages.forEach(
            m => {
               // let stringWithLinks = "Watch this on youtube.com";
                let text = processString(config)(m.text);

                if (m.isAgent) {
                    messages.push(<div style={this.agentMessageStyle} key={m.id}>{text} <p><em>{m.createdAt}</em></p></div>)
                } else {
                    messages.push(<div style={this.userMessageStyle} key={m.id}>{text} <p><em>{m.createdAt}</em></p></div>)
                }
            }
        );
        return (
            <div style={{wordBreak: "break-all"}}>
                {messages}
            </div>
        );
    }

    agentMessageStyle = {
        background: "#ddd",
        padding: "5px 10px",
        marginRight: "40px",
        borderRadius: "10px",
        marginBottom: "10px",

    };

    floatLeft = {
        float: "left",

    };

    floatRight = {
        textAlign: "right",
        fontSize: "11px",
    };

    userMessageStyle = {
        background: "#3cb868",
        color: "#ffffff",
        padding: "5px 10px",
        marginLeft: "40px",
        borderRadius: "10px",
        marginBottom: "10px",
    }
}

export default Messages;
