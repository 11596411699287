import {combineReducers} from "redux";

import metadataReducer from './metadataReducer.js';
import mainFormReducer from './mainFormReducer';
import {userReducer} from '../userState';
import {callReducer} from '../callState';
import {chatReducer} from "../chatState";
import {socketReducer} from "../socketState";

export default combineReducers({
    user: userReducer,
    call: callReducer,
    chat: chatReducer,
    socket: socketReducer,
    metadata: metadataReducer,
    mainForm: mainFormReducer,
});
