console.log();

let config = {
    frontUrl: 'https://mgik-oper.itach-soft.com',
    chatUrl: 'https://mgik.itach-soft.com/ItachChat',
    apiUrl: 'https://mgik.itach-soft.com/api',
    socketUrl: 'wss://mgik-oper.itach-soft.com:8000',
};

// // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'staging') {
// if (window.location.hostname.match(/^mgik.*/)) {
//     config = {
//         frontUrl: 'https://mgik.itach-soft.com',
//         chatUrl: 'https://mgik.itach-soft.com/ItachChat',
//         apiUrl: 'https://mgik.itach-soft.com/api',
//         socketUrl: 'wss://mgik-oper.itach-soft.com:8000',
//     };
// }

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
if (window.location.hostname.match(/^beloil.*/)) {
    config = {
        frontUrl: 'https://beloil-oper.it-minsk.by',
        chatUrl: 'https://beloil.it-minsk.by/ItachChat',
        apiUrl: 'https://beloil.it-minsk.by/api',
        socketUrl: 'wss://beloil-oper.it-minsk.by:8000',
    };
}


console.log(process.env);

export default config;
