import React from 'react';
import PropTypes from 'prop-types';

import {Alert, Button, Checkbox, Col, Form, Input, Row, Select} from 'antd';

import {sendCallBnx} from '../../api/apiFactory';

class BnxForm extends React.Component {
    placeholder = "-- Выбрать --";

    static propTypes = {
        callId: PropTypes.number,
        call: PropTypes.object,
        formItemLayout: PropTypes.object,
        tailFormItemLayout: PropTypes.object,
        metadata: PropTypes.object,
        isFieldsTouched: PropTypes.func,
    };


    state = {
        fieldsValues:
            {
                send_ca: false,
                send_email: false,
                email: '',
                processingTypeId: null,
                resultId: null
            },
        saving: false,
        emailsSended: [],
        apiSended: false,
    };


    componentDidMount() {
        console.log(this.props, 'static');
        this.setState({
                fieldsValues: {
                    ...this.state.fieldsValues,
                    processingTypeId: this.props.call.processingTypeId,
                    resultId: this.props.call.resultId,
                },
                emailsSended: this.props.call.emailsSended,
                apiSended: this.props.call.apiSended,
            },
            this.updateFormFields
        );
    }


    changeSendEmail = (e) => {
        this.setState(
            {...this.state, fieldsValues: {...this.state.fieldsValues, send_email: e.target.checked}});
    };

    updateFormFields = () => {
        this.props.form.setFieldsValue(this.state.fieldsValues);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({saving: {message: "Идет отправка", type: 'info'}});
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    console.log('Received values of form: ', values);
                    let data = await sendCallBnx(this.props.callId, {
                            "processingTypeId": values.processingTypeId || '',
                            "resultId": values.resultId || '',
                            "send_ca": values.send_ca || false,
                            "email": values.email || "",
                            "click_time": new Date()
                        }
                    );
                    console.log(new Date(), 'date');
                    this.setState({
                        emailsSended: data.emailsSended,
                        apiSended: data.apiSended,
                        typeError: data.typeError
                    });
                    if(data.apiSended === false){
                        if(data.typeError == 'convert'){
                            this.setState({saving: {message: "Идёт конвертация записи... Вскоре будет отправлено", type: 'error'}});
                            await new Promise((resolve, reject) => setTimeout(() => resolve(''), 3000));
                        } else{
                            this.setState({saving: {message: 'Ошибка при отправке', type: 'error'}});
                            await new Promise((resolve, reject) => setTimeout(() => resolve(''), 3000));
                        }
                    } else{
                        this.setState({saving: {message: "Отправлено", type: 'success'}});
                        await new Promise((resolve, reject) => setTimeout(() => resolve(''), 2000));
                    }

                } catch (e) {
                    console.log(['Rejected', e]);
                    this.setState({saving: {message: 'Ошибка при отправке', type: 'error'}});
                    await new Promise((resolve, reject) => setTimeout(() => resolve(''), 3000));
                }
                this.setState({saving: false});
            }
        });
    };


    // changeFieldState = (fieldName, val) => {
    //     this.setState({...this.state, fieldsValues: {...this.state.fieldsValues, [fieldName]: val}});
    // };


    validateBNXerrors = () => {
        let errors = [];
        if (this.props.isFieldsTouched()) {
            errors.push(`Необходимо сохранить форму перед отправкой в БНХ`);
        }
        return errors;
    };

    changeResultId = (value) => {
        this.setState({
            ...this.state,
            fieldsValues: {...this.state.fieldsValues, resultId: value}
        })
    };

    changeProcessingTypeId = (value) => {
        this.setState({
                ...this.state,
                fieldsValues: {
                    ...this.state.fieldsValues,
                    processingTypeId: value,
                    send_ca: this.state.fieldsValues.send_ca || value == 6
                }
            },
            this.updateFormFields
        );
    };

    changeSendCa = (e)=>{
        this.setState(
            {
                ...this.state, fieldsValues: {
                    ...this.state.fieldsValues, send_ca: e.target.checked
                }
            },
            this.updateFormFields
        );
        if (this.state.fieldsValues.send_ca == false)
        {
            this.setState({
                    ...this.state,
                    fieldsValues: {
                        ...this.state.fieldsValues,
                        processingTypeId: 1, send_ca: e.target.checked
                    }
                },
                this.updateFormFields
            );
        }
    };



    render() {
        const {getFieldDecorator} = this.props.form;
        const MakeOption = function (X) {
            return <Select.Option key={X.id} value={X.id}>{X.title}</Select.Option>;
        };
        const formItemLayout = this.props.formItemLayout;
        const tailFormItemLayout = this.props.tailFormItemLayout;
        const canEdit = this.props.canEdit;

        console.log(this.props, 'render');

        return (
            <Form onSubmit={this.handleSubmit}>

                <Form.Item label="Результат обращения" {...formItemLayout} >
                    {getFieldDecorator('resultId', {
                        rules: [{
                            type: 'number',
                        }, {
                            required: true,
                        }]
                    })(
                        <Select onChange={this.changeResultId} placeholder={this.placeholder}
                                disabled={!canEdit}
                        >
                            {this.props.metadata.resultOptions.map(MakeOption)}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item label="Тип обработки обращения" {...formItemLayout} >
                    {getFieldDecorator('processingTypeId', {
                        rules: [{required: true}]
                    })(
                        <Select
                            onChange={this.changeProcessingTypeId}
                            placeholder={this.placeholder}
                            disabled={!canEdit}
                        >
                            {this.props.metadata.processingOptions.map(MakeOption)}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item {...formItemLayout} label={"Отправить в ЦА"}>
                    {getFieldDecorator('send_ca', {
                        valuePropName: "checked",
                        // initialValue: this.state.fieldsValues.send_ca
                    })(
                        <Checkbox onChange={this.changeSendCa} disabled={!canEdit} />
                    )}
                </Form.Item>

                <Form.Item {...formItemLayout} label={"Отправить на почту"}>
                    <Row>
                        <Col md={2}>
                            <Form.Item>
                                {getFieldDecorator('send_email')(
                                    <Checkbox onChange={this.changeSendEmail} disabled={!canEdit} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={22}>
                            {this.state.fieldsValues.send_email &&
                            <Form.Item>
                                {
                                    getFieldDecorator('email', {
                                        rules: [{
                                            type: 'email', message: 'Неправильный формат',
                                        }, {
                                            required: true,
                                            max: 40,
                                            message: 'Ввведите E-mail!',
                                        }],
                                    })(
                                        <Input disabled={!canEdit}/>
                                    )
                                }
                            </Form.Item>
                            }
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    {this.state.saving &&
                    <Alert message={this.state.saving.message} type={this.state.saving.type} showIcon/>}

                    {this.validateBNXerrors()}
                    {this.validateBNXerrors().length === 0 && canEdit &&
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.handleSandToBnx}
                    >Отправить
                    </Button>}


                </Form.Item>

                {
                    (this.state.apiSended) &&
                    `Обращение передано в базу данных Белоруснефть`
                }

                {
                    (this.state.emailsSended.length > 0) &&
                    SendMail(this.state.emailsSended)
                }
            </Form>
        );
    }
}

const SendMail = (mailSended) => {
    let out = [<br/>];
    out.push(`Отправлено на почту:`);

    mailSended.forEach(
        (x) => {
            out.push(<br/>);
            out.push(`${x.email}  - ${x.status}`)
        }
    );
    return out;
};

const WrappedForm = Form.create({name: 'bnx'})(BnxForm);

export default WrappedForm;
