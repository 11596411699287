import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

import {Col, Layout, Menu, Row} from 'antd';
import connect from "react-redux/es/connect/connect";
import {logoutAction} from "../../state/userState";
import SocketState from "./socketState";

const {Header} = Layout;

/* eslint-disable react/prefer-stateless-function */
class PageHeader extends React.Component {

    context = null;

    constructor(props, context) {
        super(props, context);
        this.context = context;
    }

    static propTypes = {
        basename: PropTypes.string,
    };

    state = {
        redirect: null
    };

    handleClick = (e) => {
        console.log('click ', e);
        switch (e.key) {
            case 'home':
                this.props.history.push('/');
                break;
            case 'newCall':
                this.props.history.push('/call/new/edit');
                break;
            case 'logout':
                this.props.dispatch(logoutAction());
                this.props.history.push('/login');
                break;
            default:
                this.props.history.push('/' + e.key);
        }

    };


    componentWillReceiveProps(nextProps) {
        if (!nextProps.user.token) {
            this.props.history.push('/login')
        }

        if (nextProps.redirectUrl && this.props.history.slice(-1) !== nextProps.redirectUrl ){
            this.props.history.push(nextProps.redirectUrl);
        }
    }

    render() {

        const isLogin = this.props.user.token ? true : false;
        const permissionsCreate = this.props.user.permissions > 2 ? true : false;

        console.log(this.props, 'perm2');
        console.log(sessionStorage.getItem('user'), 'perm2');

        return (
            <Header>
                <Row>
                    <Col span={4}>
                        <div className="logo" style={{color:"#fff", fontWeight:"bold"}}>
                            {/*<img src={"/src/logo.png"}/> */}
                            MGIK-chat
                        </div>
                    </Col>
                    <Col span={14}>
                        {isLogin &&
                        <Menu
                            onClick={this.handleClick}
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={['2']}
                            style={{lineHeight: '64px'}}
                        >
                            {/*<Menu.Item key="home"></Menu.Item>*/}
                            <Menu.Item key="call">Список обращений</Menu.Item>
                            {permissionsCreate ? <Menu.Item key="newCall">Создать обращение</Menu.Item> : null
                            }

                        </Menu>
                        }
                    </Col>
                    <Col span={2}>
                        <SocketState/>
                    </Col>
                    <Col span={4}>
                        <div className={"float-right"}>
                            <Menu
                                onClick={this.handleClick}
                                theme="dark"
                                mode="horizontal"
                                style={{lineHeight: '64px'}}
                            >
                                {isLogin
                                    ? <Menu.Item key="logout">Logout</Menu.Item>
                                    : <Menu.Item key="login">Login</Menu.Item>
                                }
                            </Menu>
                        </div>
                    </Col>

                </Row>
            </Header>
        )
            ;
    }
}


const mapStateToProps = function (state) {
    return {
        user: state.user,
        redirectUrl: state.redirectUrl,
    };
};

export default connect(mapStateToProps)(withRouter(PageHeader));
