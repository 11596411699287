import React, {Component} from 'react';
import {Button, Col, Input, Row, Select, DatePicker} from "antd";
import moment from 'moment';

import PropTypes from 'prop-types';

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

class Filter extends Component {

    static propTypes = {
        filter: PropTypes.shape({
            id: PropTypes.string,
            status: PropTypes.string,
            clientName: PropTypes.string,
            clientPhone: PropTypes.string,
            timeFrom: PropTypes.string,
            timeTo: PropTypes.string,
        }),
        changeFilter: PropTypes.func,
        applyFilter: PropTypes.func,
    };

    clearFilters = () => {
        let filter= {}
        Object.keys(this.props.filter).forEach(
            key => {
                filter = {...filter, key: null}
            }
        );
        this.props.changeFilter(filter);
        localStorage.clear();
        this.props.applyFilter();
    };

    handleChangeType = (value) => {
        this.props.changeFilter({...this.props.filter, status: value});
    };

    changeFilterId = (e) => {
        let name = e.target.name;
        let filter = {...this.props.filter, [name]: e.target.value};
        this.props.changeFilter(filter);
    };

    changeFilter = (e) => {
        let name = e.target.name;
        let filter = {...this.props.filter, [name]: e.target.value};
        this.props.changeFilter(filter);
    };

    changeDateHandler = (dates, dateStrings) => {
        // console.log('From: ', dates[0], ', to: ', dates[1]);
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        this.props.changeFilter({
            ...this.props.filter,
            timeFrom: dateStrings[0],
            timeTo: dateStrings[1],
        });
    };

    colStyle = {
        // paddingRight: 8
    };

    render() {
        const dateFormat = 'YYYY-MM-DD';

        return (
            <div style={{padding: 8}}>
                <Row gutter={16}>
                    <Col lg={2} md={12} style={this.colStyle}>
                        Идентификатор<br/>
                        <Input
                            name={'id'}
                            value={this.props.filter.id}
                            onChange={this.changeFilterId}
                        />
                    </Col>
                    <Col lg={4} md={12} style={{width: "180px"}}>
                        Статус<br/>
                        <Select value={this.props.filter.status}
                                style={{width: "100%"}}
                                onChange={this.handleChangeType}
                        >
                            <Option value="null">&nbsp;</Option>
                            <Option value="ANSWERED">ANSWERED</Option>
                            <Option value="NO ANSWER">NO ANSWERE</Option>
                            <Option value="RECALL">RECALL</Option>
                        </Select>
                    </Col>
                    <Col lg={5} md={12} style={this.colStyle}>
                        Имя клиента<br/>
                        <Input
                            name={'clientName'}
                            value={this.props.filter.clientName}
                            onChange={this.changeFilter}
                        />
                    </Col>
                    <Col lg={5} md={12} style={this.colStyle}>
                        Телефон клиента<br/>
                        <Input
                            name={'clientPhone'}
                            value={this.props.filter.clientPhone}
                            onChange={this.changeFilter}
                        />
                    </Col>
                    <Col lg={5} md={12} style={this.colStyle}>
                        Даты<br/>
                        <RangePicker
                            value={[
                                this.props.filter.timeFrom && moment(this.props.filter.timeFrom, dateFormat),
                                this.props.filter.timeTo && moment(this.props.filter.timeTo, dateFormat)
                            ]}
                            ranges={{ "Сегодня": [moment(), moment()], 'Текущий месяц': [moment().startOf('month'), moment().endOf('month')] }}
                            onChange={this.changeDateHandler}
                        />
                    </Col>
                    <Col lg={5} md={24} style={{width: 220}}>
                        <br/>
                        <Button
                            type="primary"
                            onClick={this.props.applyFilter}
                            icon="search"
                            // size="small"
                            style={{width: 90, marginRight: 8}}
                        >
                            Найти
                        </Button>
                        <Button
                            onClick={this.clearFilters}
                        >
                            Сбросить
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Filter;
