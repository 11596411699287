import React from 'react';

import {Alert, Button, Card, Checkbox, Col, Form, Input, Row, Select} from 'antd';
import BnxForm from './BnxForm';

import {saveCall} from '../../api/apiFactory';
import Loading from "../Loading";
import {withRouter} from "react-router-dom";

const {TextArea} = Input;

class RegistrationForm extends React.Component {
    placeholder = "-- выбрать --";


    state = {
        metadata: {},
        call: {},
        errorMessage: null,
        error: false,
        loading: false,
        saving: false,
        confirmDirty: false,
        typicalQuestions: null,
        requiredFields: null,
        urgentlyShow: false,
        fields: {
            topicId: null,
            subTopicId: null,
            urgently: false,
        },
        bnx:
            {
                send_ca: false,
                send_email: false,
                email: '',
                processingTypeId: null
            }
    };

    metadata = {loaded: false};

    updateFormFields = (data) => {
        this.props.form.setFieldsValue(data);
        // this.changeTopic(data.topicId);
        this.changeSubTopic(data.subTopicId);
    };


    componentDidMount() {
        // this.setState({
        //         fields: {...this.props.call},
        //         loading: false
        //     },
        //     () => {
        //         this.updateFormFields(this.props.call);
        //     });
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.call !== nextProps.call)
            this.setState({
                    fields: {...this.props.call},
                    loading: false
                },
                () => {
                    this.updateFormFields(this.props.call);
                });
    }


    titleById = (arr, key) => {
        arr.forEach((item) => {
            if (item.id === key) {
                return item.title
            }
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.saving) {
            // Prevent double saving
            return;
        }
        this.setState({saving: {message: 'Идет сохранение', type: 'info'}});
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err && !this.state.error) {
                try {
                    // console.log('Received values of form: ', values);
                    let data = await saveCall(this.props.callId, values);
                    this.setState({fieldsValues: data, saving: {message: 'Сохранено', type: 'success'}});
                    this.updateFormFields(data);
                    await new Promise((resolve, reject) => setTimeout(() => resolve(''), 2000));

                    if (!this.props.call.id) {
                        this.props.history.push(`/call/${data.id}/edit`);
                    }
                    this.setState({saving: false, loading: false});
                } catch (e) {
                    console.log(['Rejected', e]);
                    this.setState({saving: {message: 'Ошибка при сохранении', type: 'error'}});
                    await new Promise((resolve, reject) => setTimeout(() => resolve(''), 3000));
                }
            }
            this.setState({saving: false});
        });

    };


    handleSandToBnx = () => {
        // console.log(this.state.bnx);
    };


    getTopics = () => {
        return this.props.metadata.topics;
    };

    changeTopic = (topicId) => {
        this.setState({...this.state, fields: {...this.state.fields, topicId: topicId}, urgentlyShow: topicId === 22});
    };

    changeSubTopic = (topicId) => {
        let typicalQuestions = null;
        let requiredFields = null;
        this.props.metadata.subTopics.forEach(item => {
            if (item.id === topicId) {
                typicalQuestions = item.typicalQuestions;
                requiredFields = item.requiredFields;
            }
        });
        this.setState({
            ...this.state,
            fields: {...this.state.fields, subTopicId: topicId,},
            typicalQuestions: typicalQuestions,
            requiredFields: requiredFields,
        });
    };

    changeUrgently = (e)=>{
        this.setState(
            {
                ...this.state, fieldsValues: {
                    ...this.state.fieldsValues, urgently: e.target.checked
                }
            }
        );
    };

    validationCardLoyal() {
        if (this.state.error === true)
        {
            return {
                borderColor: "red"
            }
        }
        if (this.state.error === false)
        {
            return {
                borderColor: "#d9d9d9"
            }
        }
    };

    getSubTopics = () => {
        const st = this.props.metadata.subTopics.filter((item) => {
            if (this.state.fields.topicId) {
                return item.topicId.toString() === this.state.fields.topicId.toString()
            }
            return false;
        });
        return st;
    };

    isFieldsTouched = () => {
        return this.props.form.isFieldsTouched();
    };

    outCall = () => {
        alert('Функция временно не доступна');
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        if (this.state.loading || !this.props.metadata.loaded) {
            return <Loading/>;
        }

        const MakeOption = function (X) {
            if (X.deleted != undefined && X.deleted == 1) {
                return '';
            } else {
                return <Select.Option key={X.id} value={X.id}>{X.title}</Select.Option>;
            }
        };

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const isRequired = (name) => {
            if (this.state.requiredFields) {
                if (this.state.requiredFields[name] === 1) {
                    return true;
                }
            }
            return false;
        };

        const isHidden = (name) => {
            if (this.state.requiredFields) {
                if (this.state.requiredFields[name] === 2) {
                    return true;
                }
            }
            return false;
        };


        var user = JSON.parse(sessionStorage.getItem('user')),
            canEdit = user.permissions > 1;

        console.log(user, 'prop-state');


        return (
            <div>
                <Card title="Обращение" style={{background: '#FAFAFA'}}>
                    <Form onSubmit={this.handleSubmit}>

                        {this.props.call.id &&
                            <Form.Item label="Идентификатор" {...formItemLayout} >
                                {this.props.call.id}
                            </Form.Item>
                        }

                        <Form.Item label="Источник" {...formItemLayout} >
                            {getFieldDecorator('source', {
                                rules: [{required: true}]
                            })(
                                <Select onChange={this.change} placeholder={this.placeholder} disabled={true}>
                                    {this.props.metadata.sourceOptions.map(MakeOption)}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="Тема" {...formItemLayout} >
                            {getFieldDecorator('topicId', {
                                rules: [{required: true}]
                            })(
                                <Select onChange={this.changeTopic} placeholder={this.placeholder} disabled={!canEdit}>
                                    {this.getTopics().map(MakeOption)}
                                </Select>
                            )}
                        </Form.Item>


                        <Form.Item label="Подтема" {...formItemLayout} >
                            {getFieldDecorator('subTopicId', {
                                rules: [{required: true}]
                            })(
                                <Select onChange={this.changeSubTopic} placeholder={this.placeholder} disabled={!canEdit}>
                                    {this.getSubTopics().map(MakeOption)}
                                </Select>
                            )}
                        </Form.Item>

                        {this.state.urgentlyShow && (
                            <Form.Item {...formItemLayout} label={"Срочное обращение"}>
                                {getFieldDecorator('urgently', {
                                    valuePropName: "checked",
                                })(
                                    <Checkbox onChange={this.changeUrgently} />
                                )}
                            </Form.Item>
                        )}

                        {this.state.typicalQuestions &&
                            <Form.Item label="Типовые вопросы" {...formItemLayout} >
                                {this.state.typicalQuestions}
                            </Form.Item>
                        }

                        <Form.Item label="Тип обращения" {...formItemLayout} >
                            {getFieldDecorator('typeId', {
                                rules: [{required: true}]
                            })(
                                <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                    {this.props.metadata.typeOptions.map(MakeOption)}
                                </Select>
                            )}
                        </Form.Item>

                        {!isHidden('clientName') &&
                            <Form.Item label="ФИО клиента" {...formItemLayout}  >
                                {getFieldDecorator('clientName', {
                                    rules: [{required: isRequired('clientName'), max: 40}]
                                })(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('clientPhone') &&
                            <Form.Item label="Контактный телефон" {...formItemLayout} >
                                <Row>
                                    <Col md={24}>
                                        <Form.Item>
                                            {getFieldDecorator('clientPhone', {
                                                rules: [{
                                                    required: isRequired('clientPhone'),
                                                    max: 20
                                                }]
                                            })(
                                                <Input disabled={!canEdit}/>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {/*<Col md={6}>*/}
                                    {/*<Input type={"button"} value={"Перезвонить"} onClick={this.outCall}/>*/}
                                    {/*</Col>*/}
                                </Row>
                            </Form.Item>
                        }

                        {!isHidden('clientRole') &&
                            <Form.Item label="Кто оставил обращение" {...formItemLayout} >
                                {getFieldDecorator('clientRole', {
                                    rules: [
                                        {type: 'number', required: true},
                                        {required: isRequired('clientRole'),}
                                    ]
                                })(
                                    <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                        {this.props.metadata.clientRoleOptions.map(MakeOption)}
                                    </Select>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('email') &&
                            <Form.Item
                                {...formItemLayout}
                                label="E-mail"
                            >
                                {getFieldDecorator('clientEmail', {
                                    rules: [{
                                        type: 'email', message: 'Неправильный формат',
                                    }, {
                                        required: isRequired('email'),
                                        max: 40,
                                        message: 'Ввведите E-mail!',
                                    }],
                                })(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('message') &&
                            <Form.Item
                                {...formItemLayout}
                                label="Суть обращения"
                            >
                                {getFieldDecorator('message', {
                                    rules: [{
                                        type: 'string',
                                        max: 3000,
                                    }, {
                                        required: isRequired('message'),
                                    }],
                                })(
                                    <TextArea rows={5} disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }


                        {!isHidden('stationRegionId') &&
                            <Form.Item label="Область нахождения АЗС" {...formItemLayout} >
                                {getFieldDecorator('stationRegionId', {
                                    rules: [
                                        {type: 'number',},
                                        {required: isRequired('stationRegionId'),}
                                    ]
                                })(
                                    <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                        {this.props.metadata.regionsOptions.map(MakeOption)}
                                    </Select>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('stationNumber') &&
                            <Form.Item label="Номер АЗС" {...formItemLayout} >
                                {getFieldDecorator('stationNumber', {
                                    rules: [
                                        {
                                            type: 'string',
                                            pattern: new RegExp("^[0-9]*$"),
                                            message: "Только цифры",
                                        },
                                        {required: isRequired('stationNumber')}
                                    ]
                                })(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('stationAddress') &&
                            <Form.Item label="Адрес АЗС" {...formItemLayout} >
                                {getFieldDecorator('stationAddress', {rules: [{required: isRequired('stationAddress')}]})(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('cardLoyal') &&
                            <Form.Item label="Номер карты лояльности" {...formItemLayout} >
                                {getFieldDecorator('cardLoyal', {
                                    getValueFromEvent: (e) => {
                                        const value = e.currentTarget.value;
                                        if (value.match(/^(2|3|7|9)\d*$/))
                                        {
                                            if (value.match(/^(7)\d*$/))
                                            {
                                                if (value.length === 19)
                                                {
                                                    this.state.error = false;
                                                    this.state.errorMessage = null;
                                                    return value;
                                                }
                                                else
                                                {
                                                    this.state.error = true;
                                                    this.state.errorMessage = "Номер карты лояльности должен состоять из 19 символов";
                                                    return value;
                                                }
                                            }


                                            if (value.match(/^(2|3|9)\d*$/))
                                            {
                                                if (value.length === 13)
                                                {
                                                    this.state.error = false;
                                                    this.state.errorMessage = null;
                                                    return value;
                                                }
                                                else
                                                {
                                                    this.state.error = true;
                                                    this.state.errorMessage = "Номер карты лояльности должен состоять из 13 символов";
                                                    return value;
                                                }
                                            }

                                        }
                                        else
                                        {
                                            this.state.error = false;
                                            this.state.errorMessage = "Номер карты лояльности может начинаться на цифры: 2, 3, 7, 9";
                                        }

                                        if (value.length === 0)
                                        {
                                            this.state.error = false;
                                            this.state.errorMessage = null;
                                        }
                                    },

                                    // rules:
                                    // [
                                    //     {
                                    //         required: isRequired('cardLoyal'),
                                    //         pattern: new RegExp("^(2|7|9)[0-9]*$"),
                                    //         message: "Только цифры"
                                    //     }
                                    //     {
                                    //         type: "string",
                                    //         max: 19,
                                    //         message: "Не больше 19 символов",
                                    //     }
                                    //  ]
                                })(
                                    <Input style={this.validationCardLoyal()} disabled={!canEdit}/>
                            )}<p style={{color: "red", marginTop: "-10px", marginBottom: "-25px"}}>{this.state.errorMessage}</p>

                            </Form.Item>
                        }

                        {!isHidden('cardOil') &&
                            <Form.Item label="Номер топливной карты" {...formItemLayout} >
                                {getFieldDecorator('cardOil', {
                                        // getValueFromEvent: (e) => {
                                        //     const value = e.currentTarget.value;
                                        //     const convertedValue = Number(value);
                                        //     if (isNaN(convertedValue)) {
                                        //         return value;
                                        //     }
                                        //     else
                                        //     {
                                        //         return convertedValue;
                                        //     }
                                        // },
                                        rules: [
                                            {
                                                required: isRequired('cardOil'),
                                                // type: "number"
                                                pattern: new RegExp("^[0-9]*$"),
                                                message: "Только цифры",
                                            },
                                            {
                                                type: "string",
                                                max: 19,
                                                message: "Не больше 19 символов",
                                            },
                                        ]
                                    }
                                )(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('contract') &&
                            <Form.Item label="Номер контракта" {...formItemLayout} >
                                {getFieldDecorator('contract', {rules: [{required: isRequired('contract'), max: 30}]})(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('contractAddressId') &&
                            <Form.Item label="Офис заключения договора" {...formItemLayout} >
                                {getFieldDecorator('contractAddressId', {
                                    rules: [{
                                        type: 'number',
                                    }, {
                                        required: isRequired('contractAddressId'),
                                    }]
                                })(
                                    <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                        {this.props.metadata.enterpriseOptions.map(MakeOption)}
                                    </Select>
                                )}


                                {/*{getFieldDecorator('contractAddress', {rules: [{required: false}]})(*/}
                                {/*<Input/>*/}
                                {/*)}*/}
                            </Form.Item>
                        }


                        {!isHidden('enterprise') &&
                            <Form.Item label="Наименование компании" {...formItemLayout} >
                                {getFieldDecorator('enterprise', {
                                    rules: [
                                        {
                                        required: isRequired('enterprise')},
                                        {
                                            type: "string",
                                            max: 64,
                                            message: "Не больше 64 символов",
                                        },
                                    ]
                                })(
                                    <Input disabled={!canEdit}/>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('timePeriod') &&
                            <Form.Item label="Срок рассмотрения обращения" {...formItemLayout} >
                                {getFieldDecorator('timePeriod', {
                                    rules: [{
                                        type: 'number',
                                    }, {
                                        required: isRequired('timePeriod'),
                                    }]
                                })(
                                    <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                        {this.props.metadata.timePeriodOptions.map(MakeOption)}
                                    </Select>
                                )}
                            </Form.Item>

                        }
                        {!isHidden('additionalCall') &&
                            <Form.Item label="Дополнительный звонок" {...formItemLayout} >
                                {getFieldDecorator('additionalCall', {
                                    rules: [{
                                        type: 'number',
                                    }, {
                                        required: isRequired('additionalCall'),
                                    }]
                                })(
                                    <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                        {this.props.metadata.additionalCallOptions.map(MakeOption)}
                                    </Select>
                                )}
                            </Form.Item>
                        }

                        {!isHidden('typicalTreatment') &&
                            <Form.Item label="Типовое обращение" {...formItemLayout} >
                                {getFieldDecorator('typicalTreatment', {
                                    rules: [{
                                        type: 'number',
                                    }, {
                                        required: isRequired('typicalTreatment'),
                                    }]
                                })(
                                    <Select onChange={this.change} placeholder={this.placeholder} disabled={!canEdit}>
                                        {this.props.metadata.typicalTreatmentOptions.map(MakeOption)}
                                    </Select>
                                )}
                            </Form.Item>
                        }
                        <Form.Item {...tailFormItemLayout}>

                            {!this.props.call.acl.canUpdate && <Alert message='Только для чтения' showIcon/>}
                            {this.state.saving &&
                                <Alert message={this.state.saving.message} type={this.state.saving.type} showIcon/>}

                            {this.props.call.acl.canUpdate &&
                            !this.state.saving && canEdit && <Button type="primary" htmlType="submit">Сохранить</Button>}

                            {canEdit && <Button
                                type=""
                                onClick={() => {
                                    this.props.history.push("/call")
                                }}

                                style={{marginLeft: "20px"}}
                            >Отмена</Button>}
                        </Form.Item>
                    </Form>
                </Card>

                <Card title="Отправить в Белоруснефть" style={{background: '#FAFAFA', marginTop: 50}}>
                    <BnxForm
                        callId={this.props.callId}
                        call={this.props.call}
                        formItemLayout={formItemLayout}
                        tailFormItemLayout={tailFormItemLayout}
                        metadata={this.props.metadata}
                        isFieldsTouched={this.isFieldsTouched}
                        canEdit={canEdit}
                    />
                </Card>



            </div>
        );
    }
}

const WrappedRegistrationForm = Form.create({name: 'register'})(RegistrationForm);

export default withRouter(WrappedRegistrationForm);
